<template>
    <b-input-group :size="size">
        <b-input :id="id" type="password" :value="localValue" :placeholder="placeholder" disabled />
        <b-input-group-append>
            <b-button variant="primary" @click="fillPassword">Passwort generieren</b-button>
        </b-input-group-append>
    </b-input-group>
</template>

<script>
export default {
    name: "PasswordGenerationInput",
    props: ["id", "value", "size", "placeholder", "preFill"],
    data() {
        return {
            localValue: this.value
        };
    },
    created() {
        if (this.preFill) {
            this.fillPassword();
        }
    },
    methods: {
        fillPassword() {
            this.localValue = this.generatePassword();
            this.$emit("input", this.localValue);
        },
        generatePassword(length = 20) {
            const charSet =
                "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789";
            return Array.apply(null, Array(length || 10))
                .map(function() {
                    return charSet.charAt(Math.random() * charSet.length);
                })
                .join("");
        }
    }
};
</script>