<template>
    <section v-if="data">
        <template v-for="field in fields">
            <template v-if="field.type === 'hr'">
                <hr :key="field.key" />
            </template>
            <template v-else-if="field.type === 'notice'">
                <b-alert :key="field.key" show :variant="field.variant">
                    {{ field.text }}
                </b-alert>
            </template>
            <template v-else-if="field.type === 'headline'">
                <strong :key="field.key">{{ field.text }}</strong>
            </template>
            <template v-else-if="field.hidden"> </template>
            <template v-else>
                <b-form-group
                    :key="field.key"
                    :label-cols-sm="labelColsSm"
                    :label-cols-lg="labelColsLg"
                    :label-for="field.key"
                    :label-size="size"
                    :description="field.description"
                    :disabled="field.disabled"
                    :state="field.valid"
                    :ref="field.key"
                >
                    <template v-slot:invalid-feedback>{{
                        field.invalidFeedback
                    }}</template>
                    <template v-slot:label>
                        <template v-if="field.labelBold">
                            <b>
                                {{ field.label }}
                                <template v-if="field.mandatory">
                                    <span class="text-danger">*</span>
                                </template>
                            </b>
                        </template>
                        <template v-else>
                            {{ field.label }}
                            <template v-if="field.mandatory">
                                <span class="text-danger">*</span>
                            </template>
                        </template>
                    </template>
                    <template v-if="field.type === 'select'">
                        <b-select
                            :size="size"
                            v-model="data[`${field.key}`]"
                            :options="field.options"
                            :placeholder="field.placeholder"
                            :disabled="field.disabled"
                            :state="field.valid"
                            v-on="field.handler ? field.handler : {}"
                        />
                    </template>
                    <template v-else-if="field.type === 'textarea'">
                        <template v-if="field.mode === 'autocomplete'">
                            <AutocompleteInput
                                :id="field.key"
                                :size="size"
                                :type="field.type"
                                :api="field.api"
                                :apiType="field.apiType"
                                :focused="currentFocus"
                                :placeholder="field.placeholder"
                                v-model="data[`${field.key}`]"
                                @focus="onFocus"
                            />
                        </template>
                        <template v-else-if="field.mode === 'predefined'">
                            <PredefinedInput
                                :id="field.key"
                                :size="size"
                                :type="field.type"
                                :api="field.api"
                                :apiType="field.apiType"
                                :placeholder="field.placeholder"
                                v-model="data[`${field.key}`]"
                            />
                        </template>
                        <template v-else>
                            <b-textarea
                                :size="size"
                                :placeholder="field.placeholder"
                                :disabled="field.disabled"
                                v-model="data[`${field.key}`]"
                            />
                        </template>
                    </template>
                    <template v-else-if="field.type === 'filteredselect'">
                        <FilteredSelect
                            :id="field.key"
                            :size="size"
                            :type="field.type"
                            :api="field.api"
                            :options="field.options"
                            :rels="field.rels"
                            :placeholder="field.placeholder"
                            :disabled="field.disabled"
                            v-model="data[`${field.key}`]"
                        />
                    </template>
                    <template v-else-if="field.type === 'checkbox'">
                        <b-checkbox
                            :id="field.key"
                            :value="field.checked_value"
                            :unchecked-value="field.unchecked_value"
                            v-model="data[`${field.key}`]"
                            :size="size"
                            :disabled="field.disabled"
                            >{{ field.text }}</b-checkbox
                        >
                    </template>
                    <template v-else-if="field.type === 'switch'">
                        <b-checkbox
                            :id="field.key"
                            :value="field.checked_value"
                            :unchecked-value="field.unchecked_value"
                            v-model="data[`${field.key}`]"
                            :size="size"
                            :disabled="field.disabled"
                            switch
                            >{{ field.text }}</b-checkbox
                        >
                    </template>
                    <template v-else-if="field.type === 'checkboxgroup'">
                        <b-checkbox-group
                            :id="field.key"
                            v-model="data[`${field.key}`]"
                            :options="field.options"
                            :size="size"
                            :disabled="field.disabled"
                            v-on="field.handler ? field.handler : {}"
                        />
                    </template>
                    <template
                        v-else-if="field.type === 'passwordgenerationinput'"
                    >
                        <PasswordGenerationInput
                            :id="field.key"
                            :size="size"
                            v-model="data[`${field.key}`]"
                            :preFill="meta(field, 'preFill', false)"
                        />
                    </template>
                    <template v-else-if="field.type === 'checkboxselect'">
                        <CheckboxSelect
                            :id="field.key"
                            :size="size"
                            v-model="data[`${field.key}`]"
                            :isBusy="field.busy"
                            :disabled="field.disabled"
                            :options="field.options"
                        />
                    </template>
                    <template v-else-if="field.type === 'wysiwyg'">
                        <vue-editor
                            :id="field.key"
                            v-model="data[`${field.key}`]"
                        ></vue-editor>
                    </template>
                    <template v-else>
                        <template v-if="field.onWay">
                            <b-input
                                :type="field.type"
                                :size="size"
                                :placeholder="field.placeholder"
                                :value="data[`${field.key}`]"
                                :disabled="field.disabled"
                            />
                        </template>
                        <template v-else-if="field.mode === 'autocomplete'">
                            <AutocompleteInput
                                :id="field.key"
                                :size="size"
                                :type="field.type"
                                :placeholder="field.placeholder"
                                :api="field.api"
                                :apiType="field.apiType"
                                :focused="currentFocus"
                                v-model="data[`${field.key}`]"
                                @focus="onFocus"
                            />
                        </template>
                        <template v-else-if="field.mode === 'predefined'">
                            <PredefinedInput
                                :id="field.key"
                                :size="size"
                                :type="field.type"
                                :api="field.api"
                                :apiType="field.apiType"
                                :placeholder="field.placeholder"
                                v-model="data[`${field.key}`]"
                            />
                        </template>
                        <template v-else>
                            <template v-if="isInputGroup(field)">
                                <b-input-group
                                    :prepend="field.prepend"
                                    :append="field.append"
                                >
                                    <b-input
                                        :state="field.valid"
                                        :type="field.type"
                                        :size="size"
                                        :placeholder="field.placeholder"
                                        v-model="data[`${field.key}`]"
                                        :disabled="field.disabled"
                                    />
                                </b-input-group>
                            </template>
                            <template v-else>
                                <b-input
                                    :state="field.valid"
                                    :type="field.type"
                                    :size="size"
                                    :placeholder="field.placeholder"
                                    v-model="data[`${field.key}`]"
                                    :disabled="field.disabled"
                                />
                            </template>
                        </template>
                    </template>
                    <slot
                        :name="`additionalContent(${field.key})`"
                        v-bind:data="field.key"
                    ></slot>
                </b-form-group>
            </template>
        </template>
    </section>
</template>

<script>
import Size from "@/mixins/Size/Size";
import AutocompleteInput from "@/components/AutocompleteInput/AutocompleteInput";
import PredefinedInput from "@/components/PredefinedInput/PredefinedInput";
import PasswordGenerationInput from "@/components/PasswordGenerationInput/PasswordGenerationInput";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import FilteredSelect from "@/components/FilteredSelect/FilteredSelect";
import { VueEditor } from "vue2-editor";

export default {
    name: "FormGroupBuilder",
    mixins: [Size],
    components: {
        AutocompleteInput,
        PasswordGenerationInput,
        CheckboxSelect,
        PredefinedInput,
        FilteredSelect,
        VueEditor
    },
    props: {
        fields: {
            type: [Array, Object],
            required: true
        },
        data: Object,
        labelColsSm: {
            type: Number,
            default: 3
        },
        labelColsLg: {
            type: Number,
            default: 4
        },
        refresh: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentFocus: null
        };
    },
    watch: {
        refresh: {
            handler: function(val) {
                this.$forceUpdate();
            },
            deep: true
        }
    },
    methods: {
        onFocus(key) {
            this.currentFocus = key;
        },
        meta(field, option, defaultValue) {
            if (field.meta) {
                if (field.meta[option]) {
                    return field.meta[option];
                }
            }
            return defaultValue;
        },
        isInputGroup(field) {
            return field.prepend !== null || field.append !== null;
        },
        labelText(field) {
            var label = field.label;
            if (field.mandatory) {
                label += '<span class="text-danger">*</span>';
            }
            return label;
        }
    }
};
</script>
