<template>
    <b-modal
        :id="id"
        size="sm"
        title="Löschen bestätigen"
        ok-variant="danger"
        centered
        :busy="busy"
        @ok="ok"
    >
        <slot>
            <template v-if="mode == 'deleteMulti'">
                <p>Wirklich {{ selected.length }} Datensätze löschen?</p>
            </template>
            <template v-else>
                <div v-if="record">
                    <p class="my-1"><b>Zu löschendes Objekt:</b></p>
                    <div class="small bg-dark text-light py-1 px-2 rounded">
                        <p
                            v-for="field in fields"
                            :key="field.key"
                            class="my-0"
                        >
                            <b>{{ field.label }}: </b
                            ><i>
                                {{ record[field.key] }}
                            </i>
                        </p>
                    </div>
                </div>
                <hr />
                <p>Datensatz wirklich löschen?</p>
            </template>
        </slot>

        <template #modal-ok>
            <Spinner v-if="busy" small variant="light" />
            Löschen
        </template>
        <template #modal-cancel>Abbrechen</template>
    </b-modal>
</template>

<script>
import Spinner from "@/components/Spinner/Spinner";

export default {
    name: "DeleteModal",
    components: {
        Spinner
    },
    props: {
        id: {
            type: String,
            default: "delete-modal"
        },
        busy: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: "edit"
        },
        selected: Array,
        fields: Array,
        record: [Object, Array]
    },
    methods: {
        ok(event) {
            this.$emit("ok", event);
        }
    }
};
</script>
