<template>
    <div class="checkbox-select" :id="id">
        <b-input
            v-if="enableFilter"
            class="filter"
            v-model="filter"
            block
            :size="size"
            placeholder="Nach Begriff filtern..."
        />
        <ul :class="'custom-select' + additionClasses">
            <li class="busy-text" v-if="isBusy">{{ busyText }}</li>
            <li v-else v-for="option in localOptions" :key="option.value">
                <input
                    type="checkbox"
                    :id="option.value"
                    :value="option.value"
                    v-model="localSelect"
                    :disabled="disabled"
                    @change="onChange(option.value)"
                />
                {{ option.text }}
            </li>
        </ul>
        <b-form-select
            class="true-select"
            :id="id"
            :name="name"
            :options="options"
            v-model="localSelect"
            :multiple="true"
            :required="required"
            :disabled="isBusy || disabled"
        />
    </div>
</template>

<script>
import "./CheckboxSelect.scss";
export default {
    name: "CheckboxSelect",
    props: {
        options: {
            value: {}
        },
        value: {
            type: Array,
            default: () => []
        },
        id: String,
        name: String,
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        size: String,
        selectSize: {
            type: Number,
            default: 5
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        busyText: {
            type: String,
            default: "Daten werden geladen..."
        },
        sortActive: {
            type: Boolean,
            default: false
        },
        enableFilter: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            localSelect: this.value,
            localOptions: this.options,
            filter: null
        };
    },
    computed: {
        additionClasses() {
            return (
                (this.size !== "" ? " custom-select-" + this.size : "") +
                (this.disabled || this.isBusy ? " disabled" : "") +
                (this.enableFilter ? " with-filter" : "") +
                " size-" +
                this.selectSize
            );
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.localSelect = newValue;
                this.sort();
            }
        },
        options: {
            handler(newValue) {
                this.localOptions = this.parseOptions(newValue);
                this.filter = null;
                this.sort();
            },
            deep: true,
            immediate: true
        },
        filter: {
            handler(value) {
                if (value !== null && value !== "") {
                    this.localOptions = this.parseOptions(
                        this.options.filter(
                            option =>
                                option.text
                                    .toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                this.localSelect.includes(option.value)
                        )
                    );
                    this.sort();
                } else {
                    this.localOptions = this.parseOptions(this.options);
                    this.sort();
                }
            }
        }
    },
    methods: {
        onChange(value) {
            this.$emit("input", this.localSelect);
            this.sort();
        },
        parseOptions(options) {
            var transformedOptions = options.map(option => {
                if (typeof option !== "object") {
                    return {
                        value: option,
                        text: option
                    };
                }
                return option;
            });
            return transformedOptions;
        },
        sort() {
            if (this.sortActive) {
                var selectedItems = this.localOptions.filter(item => {
                    return this.localSelect.includes(item.value);
                });
                var unselectedItems = this.localOptions.filter(item => {
                    return !this.localSelect.includes(item.value);
                });

                selectedItems = selectedItems.sort((a, b) => {
                    return a.text > b.text;
                });
                unselectedItems = unselectedItems.sort((a, b) => {
                    return a.text > b.text;
                });
                var options = [...selectedItems, ...unselectedItems];
                this.localOptions = options;
            }
        }
    }
};
</script>
