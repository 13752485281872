<template>
    <div class="table-filter">
        <b-form-group class="mb-0">
            <b-input-group>
                <b-form-input
                    v-model="localValue"
                    :size="size"
                    placeholder="Ergebnisse filtern"
                    debounce="500"
                />
                <b-input-group-append>
                    <b-button
                        :disabled="!localValue"
                        :size="size"
                        @click="localValue = ''"
                        >Zurücksetzen</b-button
                    >
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";

export default {
    name: "TableFilter",
    mixins: [Size],
    props: ["value"],
    data() {
        return {
            localValue: this.value
        };
    },
    watch: {
        localValue: {
            handler(val) {
                this.$emit("input", val);
            }
        }
    }
};
</script>
