export default {
    data() {
        return {
            ioFields: {},
            selectedFields: [],
            selected: [],
            selectAll: false,
            modificationRecord: null,
            modificationMode: null,
            editModalId: "edit-modal",
            removeModalId: "delete-modal",
            actions: {
                create: "",
                edit: "",
                delete: "",
                deleteMulti: ""
            }
        };
    },
    computed: {
        staticFields() {
            return this.fields.filter(field => field.static);
        },
        recordFields() {
            return this.fields.filter(field => !field.static);
        },
        currentRecordFields() {
            return this.recordFields.filter(field =>
                this.selectedFields.includes(field.key)
            );
        },
        currentSelectedFields() {
            const staticFields = this.staticFields;
            const recordFields = this.currentRecordFields;
            return staticFields
                .filter(field => field.position == "prepend")
                .concat(recordFields)
                .concat(
                    staticFields.filter(field => field.position == "append")
                );
        }
    },
    methods: {
        onRowSelected(items) {
            this.selected = items;
        },
        toggleAllSelectedRows() {
            if (this.selectAll) {
                this.$refs.selectableTable.selectAllRows();
            } else {
                this.$refs.selectableTable.clearSelected();
            }
        },
        _discardSelectedData(selectedData, key) {
            return selectedData.map(data => data[key]);
        },
        openModificationModal({ record, mode }) {
            this.modificationMode = mode;

            var modificationRecord = { ...record };
            if (mode == "create") {
                modificationRecord = {};
            }
            this.modificationRecord = modificationRecord;

            this.$bvModal.show(this.editModalId);
        },
        async handleModification(event) {
            event.preventDefault();
            var action = this.actions.edit;
            if (this.modificationMode == "create") {
                action = this.actions.create;
            }
            const response = await this.$store.dispatch(
                action,
                this.modificationRecord
            );

            var answer = false;
            if (typeof response === "boolean") {
                answer = response;
            } else {
                answer = response.answer;
            }
            if (answer) {
                this.$bvModal.hide(this.editModalId);
            }

            return answer;
        },
        openDeleteModal({ record, multi }) {
            var modificationRecord = record;
            var modificationMode = "delete";
            if (multi) {
                modificationRecord = [...this.selected.map(item => item.id)];
                modificationMode += "Multi";
            }
            this.modificationRecord = modificationRecord;
            this.modificationMode = modificationMode;

            this.$bvModal.show(this.removeModalId);
        },
        async deleteRecord(event) {
            event.preventDefault();
            var action = this.actions.delete;
            if (this.modificationMode == "deleteMulti") {
                action = this.actions.deleteMulti;
            }

            const answer = await this.$store.dispatch(
                action,
                this.modificationRecord
            );
            if (answer) {
                this.$bvModal.hide(this.removeModalId);
            }
        }
    }
};
