<template>
    <div class="table-export">
        <b-dropdown
            v-if="showExport"
            :disabled="!dataAvailable || busy.export"
            :size="size"
            variant="outline-primary"
        >
            <template #button-content>
                <Spinner v-if="busy.excel" small variant="primary" />
                Excel-Format
            </template>

            <b-dropdown-item @click="exportExcel('xlsx')"
                >Excel (XLSX)</b-dropdown-item
            >
            <b-dropdown-item @click="exportExcel('xls')"
                >Excel (XLS)</b-dropdown-item
            >
            <b-dropdown-item @click="exportExcel('ods')"
                >OpenOffice (ODS)</b-dropdown-item
            >
            <b-dropdown-item @click="exportExcel('csv')"
                >Kommagetrennt (CSV)</b-dropdown-item
            >
        </b-dropdown>
        <b-dropdown
            v-if="showPDF"
            :disabled="!dataAvailable || busy.export"
            :size="size"
            variant="outline-primary"
            class="mx-1"
        >
            <template #button-content>
                <Spinner v-if="busy.pdf" small variant="primary" />
                PDF-Format
            </template>

            <b-dropdown-item @click="exportPDF('P')"
                >Hochformat</b-dropdown-item
            >
            <b-dropdown-item @click="exportPDF('L')"
                >Querformat</b-dropdown-item
            >
        </b-dropdown>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import Spinner from "@/components/Spinner/Spinner";

export default {
    name: "TableExport",
    mixins: [Size],
    components: { Spinner },
    props: {
        dataAvailable: {
            type: Boolean,
            default: false
        },
        busy: {
            type: Object,
            default: () => {
                return {
                    export: false,
                    excel: false,
                    pdf: false
                };
            }
        },
        showExport: {
            type: Boolean,
            default: true
        },
        showPDF: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        exportExcel(format) {
            this.$emit("exportExcel", format);
        },
        exportPDF(orientation) {
            this.$emit("exportPDF", orientation);
        }
    }
};
</script>
