<template>
    <div class="autocomplete-input">
        <template v-if="type === 'textarea'">
            <b-textarea
                :size="size"
                :value="localValue"
                :placeholder="placeholder"
                @input="autocomplete"
                @focus="onFocus"
            />
        </template>
        <template v-else>
            <b-input
                type="text"
                :size="size"
                :value="localValue"
                :placeholder="placeholder"
                @input="autocomplete"
                @focus="onFocus"
            />
        </template>
        <aside v-if="suggestions && suggestions.length > 0 && active" class="suggestion-container">
            <b-select
                class="suggestions"
                :options="suggestions"
                :select-size="5"
                :size="size"
                ref="suggestions"
                @input="selectSuggestion"
            ></b-select>
            <div class="suggestion-bar">
                <button type="button" class="close" aria-label="Close" @click="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </aside>
    </div>
</template>

<script>
import "./AutocompleteInput.scss";

export default {
    name: "AutocompleteInput",
    props: [
        "id",
        "type",
        "value",
        "size",
        "placeholder",
        "focused",
        "api",
        "apiType"
    ],
    data() {
        return {
            suggestions: [],
            localValue: this.value
        };
    },
    computed: {
        active() {
            return this.focused === this.id;
        }
    },
    methods: {
        async autocomplete(value) {
            if (this.api) {
                const response = await this.api.suggest(this.apiType, value);
                if (response.ok) {
                    const data = response.json.collection;
                    if (data) {
                        const suggestions = data.map(suggestion => {
                            return {
                                value: suggestion.suggestion,
                                text: suggestion.suggestion
                            };
                        });
                        this.suggestions = suggestions;
                    } else {
                        this.suggestions = [];
                    }
                } else {
                    // TODO: Error handling
                }
            }
            this.$emit("input", value);
        },
        selectSuggestion(value) {
            this.localValue = value;
            this.$emit("input", value);
            this.suggestions = [];
        },
        onFocus(event) {
            this.$emit("focus", this.id);
        },
        close() {
            this.suggestions = [];
        }
    }
};
</script>