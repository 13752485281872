<template>
    <div class="predefined-input">
        <b-row>
            <b-col cols="6" class="suggestion-container">
                <b-input
                    class="suggestion-filter"
                    placeholder="Vorschläge filtern"
                    v-model="filterValue"
                    @input="filterSuggestions"
                />
                <b-select
                    class="suggestions"
                    :options="suggestionSelection"
                    :select-size="5"
                    :size="size"
                    :disabled="busy"
                    ref="suggestions"
                    @input="selectSuggestion"
                ></b-select>
            </b-col>
            <b-col cols="6" class="value-container">
                <b-textarea
                    class="value h-100"
                    :size="size"
                    v-model="localValue"
                    :placeholder="placeholder"
                    no-resize
                    @update="inputChanged"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import "./PredefinedInput.scss";
import { filter } from "minimatch";

export default {
    name: "PredefinedInput",
    props: ["id", "value", "size", "placeholder", "api", "apiType"],
    data() {
        return {
            suggestions: {
                options: [{ value: null, text: "Daten werden geladen..." }],
                filtered: []
            },
            busy: false,
            localValue: this.value,
            filterValue: ""
        };
    },
    computed: {
        suggestionSelection() {
            if (this.filterValue === "" || this.filterValue === null) {
                return this.suggestions.options;
            } else {
                return this.suggestions.filtered;
            }
        }
    },
    created() {
        this.fetch();
    },
    mounted() {
        this.$root.$on(
            `mv::predefined_input::${this.id}::options:add`,
            (value, text) => {
                this.suggestions.options.push({
                    value: value,
                    text: text ? text : value
                });
                this.suggestions.options.sort((a, b) => {
                    return a.text > b.text;
                });
            }
        );
        this.$root.$on(
            `mv::predefined_input::${this.id}::options:remove`,
            value => {
                this.localValue = null;
                this.suggestions.options = this.suggestions.options.filter(
                    option => option.value !== value
                );
                this.suggestions.filtered = this.suggestions.filtered.filter(
                    option => option.value !== value
                );
                this.inputChanged(null);
            }
        );
    },
    methods: {
        async fetch() {
            if (this.api) {
                this.busy = true;
                const response = await this.api.suggest(this.apiType);
                if (response.ok) {
                    const data = response.json.collection;
                    if (data) {
                        const suggestions = data
                            .filter(
                                suggestion =>
                                    suggestion.suggestion !== null &&
                                    suggestion.suggestion !== ""
                            )
                            .map(suggestion => {
                                return {
                                    value: suggestion.suggestion,
                                    text: suggestion.suggestion
                                };
                            });
                        this.suggestions.options = suggestions;
                    } else {
                        this.suggestions.options = [
                            { value: null, text: "Keine Daten gefunden" }
                        ];
                    }
                } else {
                    // TODO: Error handling
                }
                this.busy = false;
            }
        },
        selectSuggestion(value) {
            this.localValue = value;
            this.inputChanged(value);
        },
        filterSuggestions(value) {
            this.busy = false;
            var filteredSuggestions = this.suggestions.options.filter(
                item =>
                    item.text &&
                    item.text.toLowerCase().includes(value.toLowerCase())
            );
            if (filteredSuggestions.length === 0) {
                filteredSuggestions.push({
                    value: null,
                    text: "Keine passenden Daten zum Filter gefunden"
                });
                this.busy = true;
            }
            this.suggestions.filtered = filteredSuggestions;
        },
        inputChanged(value) {
            this.$emit("input", value);
        }
    }
};
</script>