<template>
    <div class="filtered-select">
        <b-input
            class="options-filter"
            placeholder="Vorschläge filtern"
            :size="size"
            :disabled="busyState || error || disabled"
            v-model="filterValue"
            @input="filterOptions"
        />

        <b-select
            class="options"
            :options="dataSelection"
            :select-size="5"
            :size="size"
            :disabled="busyState || empty || error || disabled"
            :value="localValue"
            ref="options"
            @input="selectOption"
        ></b-select>
    </div>
</template>

<script>
import "./FilteredSelect.scss";

export default {
    name: "FilteredSelect",
    props: [
        "id",
        "value",
        "size",
        "placeholder",
        "options",
        "busy",
        "api",
        "rels",
        "error",
        "disabled"
    ],
    data() {
        return {
            data: {
                options: [{ value: "", text: "Daten werden geladen..." }],
                filtered: []
            },
            localBusy: false,
            empty: false,
            localValue: this.value,
            filterValue: ""
        };
    },
    computed: {
        dataSelection() {
            if (this.filterValue === "" || this.filterValue === null) {
                return this.data.options;
            } else {
                return this.data.filtered;
            }
        },
        mode() {
            if (this.options) {
                return "options";
            } else {
                return "fetch";
            }
        },
        busyState() {
            if (this.busy) {
                return this.busy || this.localBusy;
            } else {
                return this.localBusy;
            }
        }
    },
    created() {
        if (this.mode === "fetch") {
            this.fetch();
        } else {
            if (this.options) {
                this.setOptionsFromProp(this.options);
            }
        }
    },
    watch: {
        options: {
            handler(val) {
                if (val) {
                    this.setOptionsFromProp(val);
                }
            },
            immediate: true
        },
        busyState: {
            handler(val) {
                if (val) {
                    this.data.options = [
                        { value: "", text: "Daten werden geladen..." }
                    ];
                }
            },
            immediate: true
        },
        value: {
            handler(val) {
                this.localValue = val;
            }
        }
    },
    methods: {
        async fetch() {
            if (this.api) {
                this.localBusy = true;
                const response = await this.api.get();
                if (response.ok) {
                    const data = response.json.collection;
                    if (data) {
                        const options = data.map(item => {
                            return {
                                value: item[this.rels.key],
                                text: this.rels.value(item)
                            };
                        });
                        this.data.options = options;
                    } else {
                        this.data.options = [
                            { value: "", text: "Keine Daten gefunden" }
                        ];
                    }
                } else {
                    // TODO: Error handling
                }
                this.localBusy = false;
            }
        },
        setOptionsFromProp(options) {
            if (options.length > 0) {
                this.data.options = options;
            }
        },
        selectOption(value) {
            this.localValue = value;
            this.inputChanged(value);
        },
        filterOptions(value) {
            this.empty = false;
            var filteredOptions = this.data.options.filter(item => {
                if (item.label) {
                    return (
                        item.label &&
                        item.label.toLowerCase().includes(value.toLowerCase())
                    );
                } else {
                    return (
                        item.text &&
                        item.text.toLowerCase().includes(value.toLowerCase())
                    );
                }
            });
            if (filteredOptions.length === 0) {
                this.empty = true;
                filteredOptions.push({
                    value: "",
                    text: "Keine passenden Daten zum Filter gefunden"
                });
            }
            this.data.filtered = filteredOptions;
        },
        inputChanged(value) {
            this.$emit("input", value);
        }
    }
};
</script>